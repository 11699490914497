import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import AdditionalSubCategories from "./AdditionalSubCategories";
import SubCategoryDropDown from "./SubCategoryDropDown";

function BasicInfo({
  values,
  errors,
  setFieldValue,
  handleChange,
  touched,
  onClose,
  handleNext,
}: any) {
  return (
    <Card>
      <CardContent>
        <Typography
          fontSize={24}
          fontWeight={600}
          gutterBottom
          sx={{ alignSelf: "start", display: "flex" }}
          marginX={1}
        >
          Basic Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Business Name"
              fullWidth
              name="businessName"
              value={values.businessName}
              onChange={handleChange}
              error={Boolean(touched.businessName && errors.businessName)}
              helperText={touched.businessName && errors.businessName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Unique Name"
              fullWidth
              name="UserName"
              value={values.UserName}
              onChange={handleChange}
              error={Boolean(touched.UserName && errors.UserName)}
              helperText={touched.UserName && errors.UserName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography
                gutterBottom
                fontWeight={500}
                alignSelf={"start"}
                fontSize={17}
                marginX={1}
              >
                Primary Sub Category
              </Typography>
              <SubCategoryDropDown
                handleSubCategoryChange={(value) =>
                  setFieldValue("primarySubCategory", value?.value)
                }
                selectedSubCategory={values.primarySubCategory}
              />
              {touched.primarySubCategory && errors.primarySubCategory && (
                <Typography color="error">
                  {errors.primarySubCategory}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <AdditionalSubCategories
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Brand Name"
              fullWidth
              name="brandName"
              value={values.brandName}
              onChange={handleChange}
              error={Boolean(touched.brandName && errors.brandName)}
              helperText={touched.brandName && errors.brandName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Website"
              fullWidth
              name="website"
              value={values.website}
              onChange={handleChange}
              error={Boolean(touched.website && errors.website)}
              helperText={touched.website && errors.website}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Business Email"
              fullWidth
              name="businessEmail"
              value={values.businessEmail}
              onChange={handleChange}
              error={Boolean(touched.businessEmail && errors.businessEmail)}
              helperText={touched.businessEmail && errors.businessEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Managed By"
              fullWidth
              name="managedBy"
              value={values.managedBy}
              onChange={handleChange}
              error={Boolean(touched.managedBy && errors.managedBy)}
              helperText={touched.managedBy && errors.managedBy}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Mobile Number"
              fullWidth
              name="mobileNumber"
              value={values.mobileNumber}
              onChange={handleChange}
              error={Boolean(touched.mobileNumber && errors.mobileNumber)}
              helperText={touched.mobileNumber && errors.mobileNumber}
            />
          </Grid>

          <Grid item xs={12}>
            <Box mt={1}>
              <Typography
                variant="h6"
                gutterBottom
                fontSize={24}
                fontWeight={600}
                sx={{ alignSelf: "start", display: "flex" }}
                marginX={1}
              >
                Owners Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="First Name"
                    fullWidth
                    name="owners.firstName"
                    value={values.owners.firstName}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.firstName && errors.owners?.firstName
                    )}
                    helperText={
                      touched.owners?.firstName && errors.owners?.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Last Name"
                    fullWidth
                    name="owners.lastName"
                    value={values.owners.lastName}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.lastName && errors.owners?.lastName
                    )}
                    helperText={
                      touched.owners?.lastName && errors.owners?.lastName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Mobile Number"
                    fullWidth
                    name="owners.mobileNumber"
                    value={values.owners.mobileNumber}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.mobileNumber &&
                        errors.owners?.mobileNumber
                    )}
                    helperText={
                      touched.owners?.mobileNumber &&
                      errors.owners?.mobileNumber
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    name="owners.email"
                    value={values.owners.email}
                    onChange={handleChange}
                    error={Boolean(
                      touched.owners?.email && errors.owners?.email
                    )}
                    helperText={touched.owners?.email && errors.owners?.email}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton variant="contained" onClick={handleNext}>
              Next
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BasicInfo;
