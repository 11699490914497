import React, { useEffect, useState, useCallback } from "react";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_CONTACT_US } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";

interface ContactUs {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  subject: string;
  description: string;
  statusHistory: {
    status: string;
    adminComment: string;
    updatedAt: string;
  }[];
}

const ContactUs: React.FC = () => {
  const [list, setList] = useState<ContactUs[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const COLUMNS = [
    { headerName: "First Name", field: "firstName", flex: 1 },
    { headerName: "Last Name", field: "lastName", flex: 1 },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Phone Number", field: "phoneNumber", flex: 1 },
    { headerName: "Subject", field: "subject", flex: 1 },
    { headerName: "Description", field: "description", flex: 2 },
    {
      headerName: "Status",
      field: "statusHistory[0].status",
      flex: 1,
      renderCell: ({ row }: { row: ContactUs }) => (
        <span>{row?.statusHistory[0]?.status}</span>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_CONTACT_US, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getContactUs?.data) {
      setList(data.getContactUs.data);
      setTotal(data.getContactUs.count);
    }
  }, [data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" color={"#00C5B9"}>
          Contact Us
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Box>
  );
};

export default ContactUs;
