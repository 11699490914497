import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Button,
} from "@mui/material";
import { Deal } from "./types";
import CustomButton from "src/components/CustomButton";

interface DealCardProps {
  deal: Deal;
  onViewDetails: (deal: Deal) => void;
}

const DealCard: React.FC<DealCardProps> = ({ deal, onViewDetails }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: 2, boxShadow: 3 }}>
      <CardMedia
        component="img"
        height="140"
        image={deal.image}
        alt={deal.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {deal.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Category: {deal.category}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Area: {deal.area}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Offer: {deal.offer} ({deal.offerType})
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Description: {deal.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Terms & Conditions: {deal.termsAndConditions}
        </Typography>
        {deal.activityStatus && (
          <Chip
            label={deal.activityStatus}
            color={deal.activityStatus === "Active" ? "success" : "warning"}
          />
        )}
        <Box mt={2}>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => onViewDetails(deal)}
          >
            View Details
          </CustomButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DealCard;
