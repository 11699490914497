import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers($page: Int!, $limit: Int!, $search: String) {
    getUsers(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        firstName
        lastName
        email
        coverImage
        profileImage
        mobileNumber
        role
        profession
        pinCode
        verified
        isVerified
        isBlocked
        deletedAt
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEventsAll(
    $page: Int
    $limit: Int
    $search: TString
    $category: [TString]
    $dateFilter: TString
    $priceFilter: TString
  ) {
    getEventsAll(
      page: $page
      limit: $limit
      search: $search
      category: $category
      dateFilter: $dateFilter
      priceFilter: $priceFilter
    ) {
      message
      statusCode
      count
      totalEvents
      data {
        _id
        title
        images
        eventBy
        description
        startTime
        endTime
        showOnWeb
        sourceType
        price
        venue
        startDate
        endDate
        language
        artist
        category
        status
        verifiedBy
        verificationDate
        desktopImage
        mobileImage
        address {
          address_1
          address_2
          pinCode
          location
          landmark
        }
      }
    }
  }
`;

// events detail
export const GET_EVENTS_BY_ID = gql`
  query GetEvent($_id: ID!) {
    getEvent(_id: $_id) {
      message
      statusCode
      data {
        _id
        title
        images
        desktopImage
        mobileImage
        eventBy
        description
        startTime
        endTime
        showOnWeb
        sourceType
        price
        type
        venue
        startDate
        endDate
        language
        artist
        category
        status
        verifiedBy
        verificationDate
        bookingPartner
        address {
          address_1
          address_2
          pinCode
          location
          landmark
        }
      }
    }
  }
`;

export const GET_PINCODES = gql`
  query GetPinCodes($page: Int, $limit: Int, $search: String) {
    getPinCodes(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        areaName
        pinCode
      }
    }
  }
`;
export const GET_ALL_PINCODES = gql`
  query GetAllPinCodes {
    getAllPinCodes {
      message
      statusCode
      count
      data {
        _id
        areaName
        pinCode
        metaTitle
        metaDescription
        metaKeywords
        imageUrl
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategories($page: Int, $limit: Int, $search: String) {
    getCategories(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        categoryName
        subCategoryCount
        subCategory {
          subCategoryName
          imageUrl
          description
          _id
        }
      }
    }
  }
`;

export const GET_SUB_CATEGORY = gql`
  query GetCategoryByIdWithPaginatedSubcategories(
    $_id: ID!
    $subCategoryPage: Int
    $subCategoryLimit: Int
  ) {
    getCategoryByIdWithPaginatedSubcategories(
      subCategoryPage: $subCategoryPage
      subCategoryLimit: $subCategoryLimit
      _id: $_id
    ) {
      data {
        _id
        categoryName
        businessProfileCount
        subCategoryCount
        subCategory {
          _id
          subCategoryName
          description
          metaTitle
          metaDescription
          metaKeywords
          overviews
        }
      }
    }
  }
`;

export const GET_SUB_CATEGORIES = gql`
  query GetCategory($_id: ID!) {
    getCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        categoryName

        subCategory {
          _id
          subCategoryName
        }
      }
    }
  }
`;
export const GET_NEWS = gql`
  query GetNews(
    $page: Int
    $limit: Int
    $search: String
    $category: NewsCategory
  ) {
    getNews(page: $page, limit: $limit, search: $search, category: $category) {
      message
      statusCode
      count
      news {
        _id
        title
        image
        shortDescription
        longDescription
        date
        category
        sourceType
        sourceId
        clickCount
      }
    }
  }
`;
export const GET_FAQS = gql`
  query GetUsers($page: Int, $limit: Int, $search: String) {
    getFaqs(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id

        question
        answer
      }
    }
  }
`;

export const GET_BLOGS = gql`
  query GetBlogs($page: Int, $limit: Int, $search: String) {
    getBlogs(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        title
        image
        publishedDate
        publishedBy
        description
        createdBy
      }
    }
  }
`;
export const GET_DEALS = gql`
  query GetDeals(
    $page: Int
    $limit: Int
    $search: String
    $selectedCategory: TString
    $filter: DealsFilter
  ) {
    getDeals(
      page: $page
      limit: $limit
      search: $search
      selectedCategory: $selectedCategory
      filter: $filter
    ) {
      message
      statusCode
      selectedLocation
      selectedCategory
      selectedDiscountBracket
      selectedDiscountPercentage
      totalDealsCount
      data {
        _id
        businessProfileId
        title
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
      }
    }
  }
`;

export const GET_SUB_CATEGORY_DATA = gql`
  query GetRelatedDataBySubCategory($subcategoryId: ID!, $pincode: String) {
    getRelatedDataBySubCategory(
      subcategoryId: $subcategoryId
      pincode: $pincode
    ) {
      subCategoryCount
      message
      statusCode
      topTenBusiness {
        _id
        businessProfileId
        subCategoryId
        rank
        title
        description
        pinCodeId {
          _id
          areaName
          pinCode
        }
        startDate
        endDate
      }
      Data {
        _id
        subCategoryName
        description
        metaTitle
        metaDescription
        metaKeywords
        subCategoryCount
      }
      topThreeBusiness {
        _id
        businessProfileId
        subCategoryId
        type
        rank
        title
        description
        pinCodeId {
          _id
          areaName
          pinCode
        }
        startDate
        endDate
      }
      faq {
        _id
        businessProfileId
        subCategoryId
        question
        answer
      }
      tags {
        _id
        key
        value
        subCategoryId
      }
    }
  }
`;

export const GET_BUSINESS_DATA = gql`
  query GetBusinessProfiles($primaryCategory: ID) {
    getBusinessProfiles(primaryCategory: $primaryCategory) {
      message
      statusCode
      data {
        _id
        userId
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        reason
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
        claimBusiness
        socialMedia {
          platform
          handle
        }
      }
    }
  }
`;

export const GET_SUBCATEGORY_BLOGS = gql`
  query GetBlogs($subCategoryId: ID) {
    getBlogs(subCategoryId: $subCategoryId) {
      message
      statusCode
      count
      data {
        _id
        title
        image
        publishedDate
        publishedBy
        description
        createdBy
        subCategoryId
        blogTitleReadingTime
        blogDescriptionReadingTime
      }
    }
  }
`;

export const GET_BUSINESS = gql`
  query GetBusinessProfilesAll($page: Int, $limit: Int, $search: String) {
    getBusinessProfilesAll(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        userId
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
        deleteFlag
      }
    }
  }
`;

export const GET_CONTACT_US = gql`
  query GetContactUs($limit: Int, $page: Int, $search: String) {
    getContactUs(limit: $limit, page: $page, search: $search) {
      statusCode
      count
      data {
        _id
        userId
        firstName
        lastName
        email
        phoneNumber
        subject
        description
        statusHistory {
          status
          adminComment
          updatedAt
        }
      }
    }
  }
`;

export const GET_TAGS = gql`
  query GetTags($page: Int, $limit: Int, $search: String) {
    getTags(page: $page, limit: $limit, search: $search) {
      message
      statusCode
      count
      data {
        _id
        key
        value
        subCategoryId
      }
    }
  }
`;

export const GET_REVIEWS = gql`
  query GetReviews($search: String, $limit: Int, $page: Int) {
    getReviews(search: $search, limit: $limit, page: $page) {
      message
      statusCode
      count
      data {
        _id
        businessProfileId
        categoryName
        ratings
        title
        description
        authorName
        authorPosition
        profileImage
        city
        createdAt
      }
    }
  }
`;

export const GET_HOME_API_DATA = gql`
  query GetHomePageAPI($categoryType: CategoryType) {
    getHomePageAPI(categoryType: $categoryType) {
      savedCategories {
        _id
        categories {
          _id
          categoryName
        }
        subCategory {
          _id
          subCategoryName
          categoryId
          metaTitle
          metaDescription
          metaKeywords
          category {
            _id
            categoryName
          }
        }
      }
    }
  }
`;

export const GET_POPULAR_LOCATIONS = gql`
  query GetPopularLocations(
    $category: PopularLocationCategory
    $pinCode: ID
    $search: TString
    $page: Int
    $limit: Int
  ) {
    getPopularLocations(
      category: $category
      pinCode: $pinCode
      search: $search
      page: $page
      limit: $limit
    ) {
      message
      statusCode
      count
      data {
        _id
        title
        images
        address
        google_location_link
        about
        category
        metaTitle
        metaDescription
        metaKeywords
        rank
        pincodeDetails {
          _id
          areaName
          pinCode
        }
        timings {
          day
          startTime
          endTime
          other
        }
      }
    }
  }
`;

export const GET_ADVERTISE = gql`
  query GetAdsWithUs {
    getAdsWithUs {
      statusCode
      count
      data {
        _id
        userId
        email
        phoneNumber
        image
        address
        description
        fullName
        companyName
      }
    }
  }
`;

export const GET_CATEGORY_DATA = gql`
  query GetCategory($_id: ID!) {
    getCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        categoryName
        imageUrl
        description
        categoryType
        businessProfileCount
        subCategoryCount
        overviews
      }
    }
  }
`;

export const GET_POPULAR_LOCATION = gql`
  query GetPopularLocationById($id: ID!) {
    getPopularLocationById(id: $id) {
      message
      statusCode
      data {
        _id
        title
        address
        UserName
        pincodeDetails {
          _id
          areaName
          pinCode
        }
        google_location_link
        images
        about
        category
        rank
        metaTitle
        metaDescription
        metaKeywords
        timings {
          day
          startTime
          endTime
          other
        }
      }
    }
  }
`;

export const GET_BUSINESS_PROFILE = gql`
  query GetBusinessProfile($_id: ID!) {
    getBusinessProfile(_id: $_id) {
      message
      statusCode
      userCanEdit
      data {
        _id
        userId
        UserName
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        totalReviewsCount
        averageRating
        claimBusiness
        dealsCount
        status
        reason
        verifiedBy
        verificationDate
        socialMedia {
          platform
          handle
        }
        primarySubCategory {
          _id
          subCategoryName
        }
        additionalSubCategories {
          _id
          subCategoryName
        }
        user {
          _id
          firstName
          lastName
          email
          coverImage
          profileImage
          mobileNumber
          role
          profession
          pinCode
          verified
          isVerified
          isBlocked
          websiteUpdates
          businessUpdates
        }
        timings {
          _id
          businessProfileId
          timings {
            day
            startTime
            endTime
          }
        }
        overviews {
          _id
          businessProfileId
          about
          dynamicOptions
        }
        address {
          _id
          businessProfileId
          addressses {
            address
            buildingOrShopNumber
            landmark
            neighborhood
            googleMapLink
            pinCode {
              _id
              areaName
            }
          }
        }
        galleries {
          _id
          image
          video
        }
        owners {
          firstName
          lastName
          mobileNumber
          email
        }
      }
    }
  }
`;

export const GET_NEWS_DATA = gql`
  query GetNewsById($_id: ID!) {
    getNewsById(_id: $_id) {
      message
      statusCode
      news {
        _id
        title
        image
        shortDescription
        longDescription
        date
        category
        sourceType
        sourceId
        shortDescriptionReadingTime
        longDescriptionReadingTime
        clickCount
      }
    }
  }
`;

export const GET_SUBCATEGORY = gql`
  query GetSubCategory($_id: ID) {
    getSubCategory(_id: $_id) {
      message
      statusCode
      data {
        _id
        subCategoryName
        description
        categoryId
        metaTitle
        metaDescription
        metaKeywords
        subCategoryCount
        additionalSubCategories
        imageUrl
        bannerImages {
          imageUrl
          redirectLink
          Title
          Description
        }
      }
    }
  }
`;

export const GET_TAGS_DATA = gql`
  query GetRelatedDataBySubCategory($subcategoryId: ID!) {
    getRelatedDataBySubCategory(subcategoryId: $subcategoryId) {
      tags {
        _id
        key
        value
        subCategoryId
      }
    }
  }
`;

export const GET_ALL_TRACKING_DATA = gql`
  query GetAllTrackingData(
    $entityType: TString
    $page: Int
    $limit: Int
    $search: String
  ) {
    getAllTrackingData(
      entityType: $entityType
      page: $page
      limit: $limit
      search: $search
    ) {
      count
      data {
        _id
        entityId
        entityType
        actionType
        userId
        timestamp
      }
    }
  }
`;

export const GET_DEAL = gql`
  query GetDeal($_id: ID!) {
    getDeal(_id: $_id) {
      message
      statusCode
      data {
        _id
        businessProfileId
        title
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
        clickCount
        discountBracket {
          minimum
          maximum
        }
        duration {
          startDate
          endDate
        }
        repeatDuration {
          repeat
          weekDays
        }
        businessProfile {
          _id
          businessName
          tag
          brandName
          brandLogo
          website
          businessEmail
          documents
          managedBy
          mobileNumber
          bgImage
          totalReviewsCount
          averageRating
          status
          reason
          verifiedBy
          verificationDate
          claimBusiness
        }
      }
    }
  }
`;
export const GET_ALL_SUBCATEGORIES = gql`
  query GetAllSubCategories {
    getAllSubCategories {
      message
      statusCode
      count
      data {
        _id
        subCategoryName
        categoryId
      }
    }
  }
`;

export const GET_REGION_DATA = gql`
  query GetPinCodeDetails($_id: ID!, $search: String) {
    getPinCodeDetails(_id: $_id, search: $search) {
      message
      statusCode
      pinCode {
        _id
        areaName
        pinCode
      }
      topBusinesses {
        _id
        businessProfileId
        subCategoryId
        type
        rank
        title
        description
        image
        startDate
        endDate
        businessProfile {
          _id
          userId
          UserName
          businessName
          tag
          brandName
          brandLogo
          website
          businessEmail
          documents
          managedBy
          mobileNumber
          bgImage
          totalReviewsCount
          averageRating
          status
          reason
          verifiedBy
          verificationDate
          deleteFlag
          claimBusiness
          yearOfEst
          socialMedia {
            platform
            handle
          }
          timings {
            _id
            businessProfileId
          }
          address {
            _id
            businessProfileId
          }
          reviews {
            _id
            businessProfileId
            categoryName
            ratings
            image
            video
            title
            description
            authorName
            authorPosition
            profileImage
            city
            createdAt
          }
          members {
            name
            email
            status
          }
        }
        pinCodeId {
          _id
          areaName
          pinCode
        }
      }
      businesses {
        _id
        userId
        UserName
        businessName
        tag
        brandName
        brandLogo
        website
        businessEmail
        documents
        managedBy
        mobileNumber
        bgImage
        status
        reason
        verifiedBy
        verificationDate
        totalReviewsCount
        averageRating
        deleteFlag
        claimBusiness
        yearOfEst
        galleries {
          _id
          image
          video
        }
        timings {
          _id
          businessProfileId
          timings {
            day
            startTime
            endTime
          }
        }
        overviews {
          _id
          businessProfileId
          about
          dynamicOptions
        }
        owners {
          firstName
          lastName
          mobileNumber
          email
        }
        address {
          _id
          businessProfileId
          addressses {
            address
            buildingOrShopNumber
            landmark
            neighborhood
            googleMapLink
          }
        }
      }
      deals {
        _id
        businessProfileId
        title
        image
        category
        subCategoryId
        area
        offer
        offerType
        description
        termsAndConditions
        deleted
        activityStatus
        clickCount
      }
      reviews {
        _id
        businessProfileId
        categoryName
        ratings
        image
        video
        title
        description
        authorName
        authorPosition
        profileImage
        city
        createdAt
      }
      popularLocations {
        _id
        title
        UserName
        images
        address
        google_location_link
        about
        category
        metaTitle
        metaDescription
        metaKeywords
      }
    }
  }
`;

export const GET_PINCODE_DETAIL = gql`
  query GetPinCode($_id: ID!) {
    getPinCode(_id: $_id) {
      message
      statusCode
      data {
        _id
        areaName
        pinCode
        metaTitle
        metaDescription
        metaKeywords
        imageUrl
      }
    }
  }
`;

export const GET_RANKS = gql`
  query GetPopularLocations {
    getPopularLocations {
      message
      statusCode
      count
      data {
        rank
      }
    }
  }
`;
export const LOGIN_ME = gql`
  query LoginMe {
    loginMe {
      message
      statusCode
      accessToken
      user {
        lastName
        email
        _id
        firstName
        role
      }
    }
  }
`;
