import React, { useEffect, useState, useCallback } from "react";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import UserModal from "./UserModal";
import { DELETE_USER } from "src/graphql/mutations";
import { GET_USERS } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import { UserTypes } from "./User";
import DeleteModel from "src/components/Common/DeleteModel";

const User: React.FC = () => {
  const [list, setList] = useState<UserTypes[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserTypes | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<UserTypes | null>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deleteUser] = useMutation(DELETE_USER);

  const COLUMNS = [
    { headerName: "First Name", field: "firstName", flex: 1 },
    { headerName: "Last Name", field: "lastName", flex: 1 },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Role", field: "role", flex: 1 },
    { headerName: "Mobile Number", field: "mobileNumber", flex: 1 },
    { headerName: "Profession", field: "profession", flex: 1 },
    {
      headerName: "Status",
      field: "deletedAt",
      flex: 1,
      renderCell: ({ row }: { row: UserTypes }) => {
        if (row?.deletedAt) {
          return (
            <div
              title={`Deleted on: ${new Date(row.deletedAt).toLocaleString()}`}
            >
              <span style={{ color: "red", fontWeight: "bold" }}>Deleted</span>
            </div>
          );
        } else {
          return (
            <div title="Active">
              <span style={{ color: "green", fontWeight: "bold" }}>Active</span>
            </div>
          );
        }
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: UserTypes }) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_USERS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getUsers?.data) {
      setList(data.getUsers.data);
    }
    setTotal(data?.getUsers?.count);
  }, [data]);

  const handleEdit = (rowData: any) => {
    setSelectedUser(rowData);
    setOpenModal(true);
  };

  const handleDelete = (rowData: UserTypes) => {
    setUserToDelete(rowData);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (userToDelete) {
        const res = await deleteUser({
          variables: { _id: userToDelete._id },
        });

        if (res.errors && res.errors.length > 0) {
          const errorMessage = res.errors[0].message;
          throw new Error(`Error: ${errorMessage}`);
        }

        toast.success("User deleted successfully!");
        setUserToDelete(null);
        setOpenConfirmDialog(false);
        refetch();
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the user."
      );
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Users
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <UserModal
        open={openModal}
        handleClose={() => handleCloseModal()}
        user={selectedUser}
        refetchUsers={() => refetch()}
      />
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this user?"
      />
    </Box>
  );
};

export default User;
