import React, { useState, useEffect } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_BLOG, DELETE_BLOG, UPDATE_BLOG } from "src/graphql/mutations";
import { GET_SUBCATEGORY_BLOGS } from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CustomTable from "src/components/CustomTable";
import CustomButton from "src/components/CustomButton";
import { useParams } from "react-router";
import BlogModal from "./BlogModal";
import DeleteModel from "src/components/Common/DeleteModel";
import { Blog } from "./SubCategory";
import styles from "./SubCategory.module.css";

const BlogTable: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [newBlog, setNewBlog] = useState<Blog>({
    title: "",
    image: "",
    publishedDate: "",
    publishedBy: "",
    description: "",
    createdBy: "",
    subCategoryId: id || null,
    blogTitleReadingTime: null,
    blogDescriptionReadingTime: null,
  });
  const [editBlogId, setEditBlogId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteBlogId, setDeleteBlogId] = useState<string | null>(null);

  const [AddBlog] = useMutation(ADD_BLOG);
  const [DeleteBlog] = useMutation(DELETE_BLOG);
  const [UpdateBlog] = useMutation(UPDATE_BLOG);

  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  const { loading, error, data, refetch } = useQuery(GET_SUBCATEGORY_BLOGS, {
    variables: {
      subCategoryId: id,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      setBlogs(data.getBlogs.data || []);
    }
  }, [data]);

  const COLUMNS = [
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Published Date", field: "publishedDate", flex: 1 },
    { headerName: "Published By", field: "publishedBy", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditBlog(row._id);
            }}
            aria-label="edit"
            className={styles.editIcon}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            className={styles.deleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteBlogId(row._id);
              setOpenDeleteModel(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      page: newPage,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleDeleteBlog = async (id: string) => {
    try {
      await DeleteBlog({
        variables: {
          _id: id,
        },
      });
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
      toast.success("Blog deleted successfully!");
    } catch (error: any) {
      toast.error(
        error.message || "An error occurred while deleting the blog."
      );
    }
  };

  const handleEditBlog = (id: string) => {
    const blogToEdit = blogs.find((blog) => blog._id === id);
    if (blogToEdit) {
      setNewBlog(blogToEdit);
      setEditBlogId(id);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setNewBlog({
      title: "",
      image: "",
      publishedDate: "",
      publishedBy: "",
      description: "",
      createdBy: "",
      subCategoryId: id || null,
      blogTitleReadingTime: null,
      blogDescriptionReadingTime: null,
    });
    setEditBlogId(null);
    setOpenModal(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteBlogId) {
      await handleDeleteBlog(deleteBlogId);
      setDeleteBlogId(null);
      setOpenDeleteModel(false);
    }
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box mt={0}>
      {openModal ? (
        <BlogModal
          open={openModal}
          newBlog={newBlog}
          setNewBlog={setNewBlog}
          handleClose={handleCloseModal}
          isEdit={!!editBlogId}
        />
      ) : (
        <>
          <Box className={styles.buttonContainer}>
            <Box>
              <Typography variant="h6" className={styles.header}>
                Blogs
              </Typography>
            </Box>
            <Box width={100}>
              <CustomButton
                onClick={() => setOpenModal(true)}
                variant="contained"
              >
                Create
              </CustomButton>
            </Box>
          </Box>

          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={blogs}
              paginationModel={{
                page: pagination.page - 1,
                pageSize: pagination.pageSize,
              }}
              onPageSizeChange={handlePageSizeChange}
              totalCount={blogs.length}
              onPageChange={handlePageChange}
            />
          </Box>
        </>
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this blog?"
        />
      )}
    </Box>
  );
};

export default BlogTable;
