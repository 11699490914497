import React, { useEffect, useState, useMemo } from "react";
import { Tabs, Tab, Box, Typography, Divider } from "@mui/material";
import TopTenBusinessTable from "./TopTenBusinessTable";
import { useQuery } from "@apollo/client";
import { GET_SUB_CATEGORY_DATA } from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import TopThreeBusinessTable from "./TopThreeBusinessTable";
import FaqTable from "./FaqTable";
import BlogTable from "./BlogTable";
import TagManagementTable from "./tagsTable";
import { TabData } from "./SubCategory";
import backButton from "../../Images/backButton.svg";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./SubCategory.module.css"; // Import your CSS module

const SubcategoryTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { loading, error, data, refetch } = useQuery(GET_SUB_CATEGORY_DATA, {
    variables: { subcategoryId: id },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const topTenBusiness = data?.getRelatedDataBySubCategory?.topTenBusiness;
  const topThreeBusiness = data?.getRelatedDataBySubCategory?.topThreeBusiness;
  const faqData = data?.getRelatedDataBySubCategory?.faq;
  const tagData = data?.getRelatedDataBySubCategory?.tags;

  const tabData: TabData = useMemo(
    () => ({
      top10: (
        <TopTenBusinessTable
          initialBusinesses={topTenBusiness}
          reload={refetch}
        />
      ),
      top3: (
        <TopThreeBusinessTable
          initialBusinesses={topThreeBusiness}
          reload={refetch}
        />
      ),
      faq: <FaqTable initialFaqs={faqData} reload={refetch} />,
      blogs: <BlogTable />,
      tags: <TagManagementTable initialTags={tagData} reload={refetch} />,
    }),
    [topTenBusiness, topThreeBusiness, faqData, tagData, refetch]
  );

  const tabs: string[] = ["Top 10", "Top 3", "FAQ", "Blogs", "Tags"];

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <img
            src={backButton}
            alt="backButton"
            className={styles.backButton} // Use class from CSS
            onClick={handleBackButtonClick}
          />
          <Typography variant="h4" className={styles.subCategoryName}>
            {data?.getRelatedDataBySubCategory?.Data?.subCategoryName}
          </Typography>
        </Box>
        <Divider />
        <Box mt={0}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="subcategory tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ borderBottom: "1px solid #e0e0e0" }}
          >
            {tabs.map((label, index) => (
              <Tab
                key={index}
                label={label}
                className={`${styles.tabStyle} ${
                  activeTab === index ? styles.activeTab : styles.inactiveTab
                }`} // Use class from CSS
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      <Box className={styles.tabsContainer}>
        {tabData[tabs[activeTab].toLowerCase().replace(/\s/g, "")]}
      </Box>
    </Box>
  );
};

export default React.memo(SubcategoryTabs);
