import * as yup from "yup";
export enum UserRole {
  ADMIN = "Admin",
  SUPERADMIN = "SuperAdmin",
  USER = "User",
}
export const userRoleOptions = Object.keys(UserRole).map((key) => ({
  value: UserRole[key as keyof typeof UserRole],
  label: UserRole[key as keyof typeof UserRole],
}));

export const validationSchema = (isUpdate: boolean) =>
  yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: !isUpdate
      ? yup.string().email("Invalid email format").required("Email is required")
      : yup.string(),
    mobileNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Mobile Number must be a number")
      .length(10, "Mobile Number must be 10 digit number")
      .required("Mobile Number is required"),
    profession: yup.string().required("Profession is required"),
    password: !isUpdate
      ? yup
          .string()
          .min(8, "Password must be at least 8 characters")
          .required("Password is required")
      : yup.string(),
  });

export interface UserModalProps {
  open: boolean;
  handleClose: () => void;
  user?: any; // Adjust type as necessary
  refetchUsers: () => void; // Define the correct type for refetchUsers
}

export interface UserTypes {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  mobileNumber: number;
  profession: string;
  pinCode: string;
  deletedAt?: string | null;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  profession: string;
  pincode: string;
  password: string;
  role: UserRole;
}

export interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  profession?: string;
  pincode?: string;
  password?: string;
}
