import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  Divider,
  Avatar,
  Grid,
  Link,
  IconButton,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import CustomButton from "src/components/CustomButton";

interface PopularLocationModalProps {
  open: boolean;
  onClose: () => void;
  locationData: any;
}

const PopularLocationDetails: React.FC<PopularLocationModalProps> = ({
  open,
  onClose,
  locationData,
}) => {
  if (!locationData) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "90%",
            maxWidth: "lg",
            p: 3,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            transform: "translate(-50%, -50%)",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          <Typography
            variant="h5"
            color="#00C5B9"
            textAlign="center"
            fontWeight="bold"
            mb={2}
          >
            Popular Location Details
          </Typography>
          <Typography>No location data available.</Typography>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <CustomButton onClick={onClose} variant="outlined" color="primary">
              Close
            </CustomButton>
          </Box>
        </Box>
      </Modal>
    );
  }

  const {
    title,
    UserName,
    images,
    address,
    google_location_link,
    about,
    category,
    timings,
    pincodeDetails,
  } = locationData;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "90%",
          maxWidth: "lg",
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          transform: "translate(-50%, -50%)",
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <Typography
          variant="h5"
          color="#00C5B9"
          textAlign="center"
          fontWeight="bold"
          mb={2}
        >
          Popular Location Details
        </Typography>

        {/* Location Images */}
        <Box mb={3}>
          {images && images.length > 0 ? (
            <Grid container spacing={2}>
              {images.map((image: string, index: number) => (
                <Grid item xs={12} sm={4} key={index}>
                  <img
                    src={image}
                    alt={`Location image ${index + 1}`}
                    style={{ width: "100%", height: "auto", borderRadius: 8 }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No images available.</Typography>
          )}
          <Box mt={2} textAlign="center">
            <IconButton color="primary">
              <PhotoCamera />
            </IconButton>
          </Box>
        </Box>

        {/* Location Details */}
        <Box>
          <Typography variant="h6" fontWeight="bold" mb={2}>
            {title}
          </Typography>
          <Typography variant="body1" fontWeight="bold" mb={1}>
            Username: {UserName}
          </Typography>
          <Typography variant="body1" fontWeight="bold" mb={1}>
            Address:
          </Typography>
          <Typography variant="body2" mb={2}>
            {address}
          </Typography>
          {google_location_link && (
            <Typography variant="body2" mb={2}>
              Google Location:{" "}
              <Link
                href={google_location_link}
                target="_blank"
                rel="noopener"
                color="primary"
              >
                View on Google Maps
              </Link>
            </Typography>
          )}
          <Typography variant="body1" fontWeight="bold" mb={1}>
            About:
          </Typography>
          <Typography
            variant="body2"
            mb={2}
            dangerouslySetInnerHTML={{ __html: about }}
          />
          <Typography variant="body1" fontWeight="bold" mb={1}>
            Category:
          </Typography>
          <Typography variant="body2" mb={2}>
            {category}
          </Typography>

          {/* Timings */}
          <Typography variant="body1" fontWeight="bold" mb={1}>
            Timings:
          </Typography>
          {timings && timings.length > 0 ? (
            <Box mb={2}>
              {timings.map((timing: any, index: number) => (
                <Typography key={index} variant="body2">
                  {timing.day}: {timing.startTime} - {timing.endTime} (
                  {timing.other})
                </Typography>
              ))}
            </Box>
          ) : (
            <Typography>No timings available.</Typography>
          )}

          {/* Pincode Details */}
          <Typography variant="body1" fontWeight="bold" mb={1}>
            Pincode Details:
          </Typography>
          <Typography variant="body2" mb={2}>
            Area Name: {pincodeDetails?.areaName}
          </Typography>
          <Typography variant="body2">
            Pin Codes:{" "}
            {pincodeDetails?.pinCode.map((data: any, index: number) => {
              return (
                <div>
                  {data?.postoffice}- {data?.pincode}
                </div>
              );
            })}
          </Typography>
        </Box>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <CustomButton onClick={onClose} variant="outlined" color="primary">
            Close
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopularLocationDetails;
