import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React from "react";
import CategoryIcon from "@mui/icons-material/Category";
import NewsIcon from "@mui/icons-material/Announcement";
import EventIcon from "@mui/icons-material/Event";
import BlogIcon from "@mui/icons-material/Forum";
import FaqIcon from "@mui/icons-material/Help";
import BusinessIcon from "@mui/icons-material/Business";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PreviewIcon from "@mui/icons-material/Preview";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import TagIcon from "@mui/icons-material/Tag";
import { ROUTES } from "src/constant";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ReceiptLongOutlined,
  Settings,
  TrackChanges,
} from "@mui/icons-material";

interface MenuItem {
  id: number;
  menuText: string;
  route: string;
  icon: React.ReactNode;
}
const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current location to highlight active route
  const drawerWidth = 240;

  // Define the menu items
  const MenuItemsArray: MenuItem[] = [
    { id: 1, menuText: "Users", icon: <PersonIcon />, route: ROUTES.USERS },
    { id: 2, menuText: "Events", icon: <EventIcon />, route: ROUTES.EVENTS },
    {
      id: 3,
      menuText: "Pincode",
      icon: <FiberPinIcon />,
      route: ROUTES.PINCODES,
    },
    { id: 5, menuText: "Deals", icon: <LocalOfferIcon />, route: ROUTES.DEALS },
    {
      id: 6,
      menuText: "Category",
      icon: <CategoryIcon />,
      route: ROUTES.CATEGORY,
    },
    { id: 7, menuText: "Blogs", icon: <BlogIcon />, route: ROUTES.BLOGS },
    { id: 8, menuText: "Faq", icon: <FaqIcon />, route: ROUTES.FAQ },
    {
      id: 9,
      menuText: "Business",
      icon: <BusinessIcon />,
      route: ROUTES.BUSINESS,
    },
    {
      id: 10,
      menuText: "Advertise ",
      icon: <FeaturedVideoIcon />,
      route: ROUTES.ADVERTISE,
    },
    {
      id: 11,
      menuText: "Contact Us",
      icon: <AddIcCallIcon />,
      route: ROUTES.CONTACT_US,
    },
    { id: 12, menuText: "Tags", icon: <TagIcon />, route: ROUTES.TAGS },
    { id: 13, menuText: "Review", icon: <PreviewIcon />, route: ROUTES.REVIEW },
    {
      id: 14,
      menuText: "Popular Location",
      icon: <LocationOnIcon />,
      route: ROUTES.POPULAR_LOCATION,
    },
    {
      id: 15,
      menuText: "Home Page Setting",
      icon: <Settings />,
      route: ROUTES.HOME_PAGE_SETTINGS,
    },
    {
      id: 16,
      menuText: "Tracking Data",
      icon: <TrackChanges />,
      route: ROUTES.TRACKING_DATA,
    },
    {
      id: 17,
      menuText: "Region",
      icon: <ReceiptLongOutlined />,
      route: ROUTES.Region,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {MenuItemsArray.map((element: MenuItem) => {
            const { id, menuText, icon, route } = element;
            const isSelected = (route: string) => {
              return location.pathname.startsWith(route);
            };
            return (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  onClick={() => navigate(route)}
                  sx={{
                    "&:hover": {
                      backgroundColor: isSelected(route) ? "#00C1BC" : "#eeeee", // Change background color on hover
                      color: "bleck", // Change text color on hover
                    },
                    backgroundColor: isSelected(route)
                      ? "#00C4B9"
                      : "transparent", // Highlight selected item
                    color: isSelected(route) ? "white" : "inherit", // Change text color if selected
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: isSelected(route) ? "white" : "inherit", // Change icon color if selected
                      "&:hover": {
                        // color: "primary.main",
                      },
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={menuText} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
