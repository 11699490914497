import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { uploadImage } from "src/components/Common/Utils";
import { BlogModalProps } from "./SubCategory";
import CustomButton from "src/components/CustomButton";
import { useMutation } from "@apollo/client";
import { ADD_BLOG, UPDATE_BLOG } from "src/graphql/mutations";
import backButton from "../../Images/backButton.svg";
import * as mammoth from "mammoth";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./SubCategory.module.css";

const BlogPage: React.FC<BlogModalProps> = ({
  handleClose,
  newBlog,
  setNewBlog,
  isEdit = false,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [docFile, setDocFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(
    newBlog?.image || null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [AddBlog] = useMutation(ADD_BLOG);
  const [UpdateBlog] = useMutation(UPDATE_BLOG);

  useEffect(() => {
    if (newBlog?.image) {
      setImageUrl(newBlog.image);
    }
  }, [newBlog]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleDocChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setDocFile(file);
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const arrayBuffer = event.target.result;
        try {
          const result = await mammoth.convertToHtml(
            { arrayBuffer },
            { styleMap: ["p[style-name='Normal'] => p"] }
          );
          formik.setFieldValue("description", result.value);
        } catch (error) {
          toast.error("Error converting .docx to HTML");
          console.error("Conversion Error:", error);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      toast.error("Please upload a valid .docx file");
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    publishedDate: Yup.date().required("Published date is required"),
    publishedBy: Yup.string().required("Published by is required"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
  });

  const formik = useFormik({
    initialValues: {
      title: newBlog?.title || "",
      publishedDate: newBlog?.publishedDate || "",
      publishedBy: newBlog?.publishedBy || "",
      description: newBlog?.description || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let finalImageUrl = imageUrl;
        if (imageFile) {
          const res = await uploadImage(imageFile);
          finalImageUrl = res?.uploadImage?.url;
        }

        const variables = {
          _id: isEdit ? newBlog._id : undefined,
          input: {
            ...values,
            image: finalImageUrl,
            description: values.description, // Use formik.values.description
            subCategoryId: newBlog.subCategoryId,
          },
        };

        const mutation = isEdit ? UpdateBlog : AddBlog;
        const res = await mutation({ variables });

        if (res.errors) {
          throw new Error("Internal Error");
        }

        toast.success(
          isEdit ? "Blog updated successfully!" : "Blog added successfully!"
        );

        setNewBlog({
          title: "",
          image: "",
          publishedDate: "",
          publishedBy: "",
          description: "",
          createdBy: "",
          subCategoryId: newBlog.subCategoryId,
        });
      } catch (error: any) {
        toast.error(
          error.message || "An error occurred while saving the blog."
        );
        console.error("Error uploading image or doc:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box>
      <Box className={styles.container}>
        <img
          src={backButton}
          alt="Back"
          height={25}
          width={25}
          className={styles.backButton}
          onClick={handleClose}
        />
        <Typography variant="h4" className={styles.header}>
          {isEdit ? "Update Blog Post" : "Create New Blog Post"}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />

          <Box className={styles.imageContainer}>
            <input
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="blog-image-upload"
            />
            <label htmlFor="blog-image-upload">
              {imageUrl ? (
                <>
                  <img
                    src={imageUrl}
                    alt="Blog"
                    className={styles.imagePreview}
                  />
                  <IconButton
                    className={styles.closeIcon}
                    onClick={() => setImageUrl(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <Typography color="primary">Upload Image</Typography>
              )}
            </label>
          </Box>

          <TextField
            fullWidth
            label="Published Date"
            variant="outlined"
            type="date"
            name="publishedDate"
            InputLabelProps={{ shrink: true }}
            value={formik.values.publishedDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.publishedDate &&
              Boolean(formik.errors.publishedDate)
            }
            helperText={
              formik.touched.publishedDate &&
              (formik.errors.publishedDate as string)
            }
          />

          <TextField
            fullWidth
            label="Published By"
            variant="outlined"
            name="publishedBy"
            value={formik.values.publishedBy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.publishedBy && Boolean(formik.errors.publishedBy)
            }
            helperText={
              formik.touched.publishedBy &&
              (formik.errors.publishedBy as string)
            }
          />

          <Box>
            <Typography>Description:</Typography>
            <Box className={styles.descriptionContainer}>
              <div
                className={styles.htmlPreview}
                dangerouslySetInnerHTML={{
                  __html: formik.values.description || "",
                }}
              />
            </Box>
          </Box>

          <Box>
            <CustomButton
              variant="contained"
              component="label"
              className={styles.uploadButton}
            >
              Upload Doc
              <input type="file" hidden onChange={handleDocChange} />
            </CustomButton>
            {docFile && (
              <Typography variant="body2" className={styles.fileInfo}>
                {docFile.name}
              </Typography>
            )}
          </Box>

          <Box>
            {loading ? (
              <CircularProgress className={styles.loadingSpinner} />
            ) : (
              <CustomButton type="submit">
                {isEdit ? "Update Blog" : "Add Blog"}
              </CustomButton>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BlogPage;
