import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "./PrivateLayout.module.scss";
import Header from "./Header.tsx";
import SideBar from "./SideBar";
import { useNavigate } from "react-router";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_ME } from "src/graphql/query";

interface Props {
  children: React.ReactNode;
}

const PrivateLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [LoginMe] = useLazyQuery(LOGIN_ME);
  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        navigate("/");
      } else {
        const response = await LoginMe();
        if (response?.data?.loginMe?.user?.role?.includes("Admin")) {
          return;
        } else {
          localStorage.removeItem("accessToken");
          navigate("/login");
        }
      }
    };

    checkToken();
  }, [navigate]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <SideBar />
      <div className={styles.childrenContainer}>{children}</div>
    </Box>
  );
};

export default PrivateLayout;
