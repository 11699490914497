import React from "react";
import { Modal, Box, TextField, Typography, FormControl } from "@mui/material";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomButton from "src/components/CustomButton";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import { Business } from "./SubCategory";
import styles from "./SubCategory.module.css";
interface TopThreeBusinessModalProps {
  open: boolean;
  handleClose: () => void;
  handleAddBusiness: (values: any) => void;
  newBusiness: any;
  setNewBusiness: React.Dispatch<React.SetStateAction<Omit<Business, "_id">>>;
  pincodeOptions: { value: string; label: string }[];
  businessProfiles: any;
  usedRanks: number[];
}

const TopThreeBusinessModal: React.FC<TopThreeBusinessModalProps> = ({
  open,
  handleClose,
  handleAddBusiness,
  newBusiness,
  businessProfiles,
  usedRanks = [],
}) => {
  const initialValues = {
    title: newBusiness.title || "",
    description: newBusiness.description || "",
    rank: newBusiness.rank || "",
    pinCodeId: newBusiness.pinCodeId?._id || "",
    businessProfileId: newBusiness.businessProfileId || "",
    startDate: newBusiness.startDate
      ? new Date(newBusiness.startDate).toISOString().split("T")[0]
      : "",
    endDate: newBusiness.endDate
      ? new Date(newBusiness.endDate).toISOString().split("T")[0]
      : "",
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters")
      .max(100, "Title cannot exceed 100 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters")
      .max(500, "Description cannot exceed 500 characters"),
    rank: Yup.number()
      .required("Rank is required")
      .min(1, "Rank must be at least 1")
      .max(3, "Rank cannot exceed 3"),
    pinCodeId: Yup.string().required("Pincode is required"),
  });

  const rankOptions = Array.from({ length: 3 }, (_, i) => {
    const rankValue = i + 1;
    return {
      value: rankValue,
      label: rankValue.toString(),
      isDisabled: usedRanks.includes(rankValue),
    };
  });

  const handleFormSubmit = (values: typeof initialValues) => {
    handleAddBusiness(values);
    handleClose();
  };

  const businessOptions =
    businessProfiles?.map((business: any) => ({
      value: business._id,
      label: `${business.businessName}`,
    })) || [];

  return (
    <Modal open={open} onClose={handleClose} className={styles.modalContent}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Box
              sx={{
                width: 600,
                margin: "auto",
                marginTop: "10%",
                padding: 2,
                backgroundColor: "white",
                borderRadius: 1,
              }}
            >
              <Typography variant="h6" gutterBottom className={styles.header}>
                {newBusiness._id
                  ? "Update Top 3 Business"
                  : "Add Top 3 Business"}
              </Typography>

              <FormControl fullWidth sx={{ zIndex: "9999" }}>
                <Select
                  value={businessOptions.find(
                    (option: any) => option.value === values.businessProfileId
                  )}
                  maxMenuHeight={200}
                  placeholder="Select Business"
                  options={businessOptions}
                  onChange={(selectedOption) =>
                    setFieldValue("businessProfileId", selectedOption?.value)
                  }
                />
                {errors.businessProfileId && touched.businessProfileId && (
                  <Typography color="error">
                    {errors?.businessProfileId as any}
                  </Typography>
                )}
              </FormControl>

              <Field
                name="title"
                as={TextField}
                label="Title"
                variant="outlined"
                fullWidth
                margin="normal"
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
              />
              <Field
                name="description"
                as={TextField}
                label="Description"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
              <Box marginTop={2}>
                <Field
                  name="startDate"
                  as={TextField}
                  margin="dense"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={touched.startDate && Boolean(errors.startDate)}
                  helperText={touched.startDate && errors.startDate}
                />
              </Box>
              <Box marginTop={2}>
                <Field
                  name="endDate"
                  as={TextField}
                  margin="dense"
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={touched.endDate && Boolean(errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                />
              </Box>

              <FormControl
                fullWidth
                sx={{
                  zIndex: "9999999",
                  position: "relative",
                  marginBottom: "12px",
                  marginTop: "12px",
                }}
              >
                <Select
                  value={rankOptions.find(
                    (option) => option.value === values.rank
                  )}
                  options={rankOptions}
                  placeholder="Select Rank"
                  maxMenuHeight={150}
                  onChange={(selectedOption) =>
                    setFieldValue("rank", selectedOption?.value)
                  }
                  isOptionDisabled={(option) => option.isDisabled}
                />
                {errors.rank && touched.rank && (
                  <Typography color="error">{errors?.rank as any}</Typography>
                )}
              </FormControl>

              <PincodeSelectDropDown
                handlePincodeChange={(selectedOption) => {
                  setFieldValue("pinCodeId", selectedOption?.value || "");
                }}
                selectedPincode={values.pinCodeId}
              />

              {errors.pinCodeId && touched.pinCodeId && (
                <Typography color="error" variant="caption">
                  {errors?.pinCodeId as string}
                </Typography>
              )}
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <CustomButton
                  onClick={handleClose}
                  variant="outlined"
                  style={{ marginRight: 2 }}
                >
                  Cancel
                </CustomButton>
                <CustomButton type="submit" color="primary" variant="contained">
                  {newBusiness._id ? "Save" : "Add"}
                </CustomButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TopThreeBusinessModal;
