import { toast } from "react-toastify";

export interface PincodeOption {
  value: string;
  label: string;
}

const url = process.env.REACT_APP_GRAPHQL_ENDPOINT ?? "";

// Reusable function to handle image uploads (single or multiple)
const uploadToServer = async (formData: FormData, operationName: string) => {
  try {
    const token = localStorage.getItem("accessToken")?.replace(/^"|"$/g, "");
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "x-apollo-operation-name": operationName,
        "apollo-require-preflight": "true",
        authorization: `Bearer ${token} `,
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error(`Error in ${operationName}:`, error);
    toast.error(`Error uploading images for ${operationName}.`);
    throw error;
  }
};

// For multiple image upload
export const uploadImages = async (imagesData: File[]) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($files: [Upload!]!) {
          uploadMultipleImages(files: $files) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        files: new Array(imagesData.length).fill(null),
      },
    })
  );

  const map: { [key: string]: string[] } = {};
  imagesData.forEach((_, index) => {
    map[index.toString()] = [`variables.files.${index}`];
  });

  formData.append("map", JSON.stringify(map));
  imagesData.forEach((image, index) => {
    formData.append(index.toString(), image);
  });

  return await uploadToServer(formData, "uploadMultipleImages");
};

// For single image upload
export const uploadImage = async (imageData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!) {
          uploadImage(file: $file) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", imageData);

  return await uploadToServer(formData, "uploadImage");
};

export const uploadFile = async (videoData: File) => {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!) {
          uploadFile(file: $file) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", videoData);

  return await uploadToServer(formData, "uploadImage");
};

export const uploadExcel = async (excelData: File) => {
  const formData = new FormData();

  // Set up the GraphQL mutation for uploading the Excel file
  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation UploadBusinessProfiles($file: Upload!) {
          uploadBusinessProfiles(file: $file) {
            errors {
              data
              message
            }
            message
            statusCode
            successfullyUploadedBusinesses
            errorUploadedBusinesses
          }
        }
      `,
      variables: {
        file: null,
      },
    })
  );

  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));

  formData.append("0", excelData);
  return await uploadToServer(formData, "uploadBusinessProfiles");
};
