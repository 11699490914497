import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButton from "src/components/CustomButton";
import CustomTextField from "src/components/CustomTextfield";
import CustomTypography from "src/components/CustomTypography";
import AuthHeader from "./AuthHeader";
import styles from "./SignIn.module.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SET_USERS } from "src/store/Action/AuthActions/SetUserAction";
import { toast } from "react-toastify";
import { ROUTES } from "src/constant";
import { localStorageSetItem } from "src/utils/localStorage";

const SignIn = () => {
  const dispatch = useDispatch();
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSignInClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await dispatch(SET_USERS(email, password));
      if (res?.signInByAdmin?.accessToken) {
        localStorageSetItem("accessToken", res?.signInByAdmin?.accessToken);
        toast.success(res?.signInByAdmin?.message);
        navigate(ROUTES?.USERS);
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error: any) {
      toast.error(error?.message || "Error signing in");
      setError(error?.message || "An error occurred during sign in.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate(ROUTES?.USERS);
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show); // Toggle password visibility

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles?.formContainer}>
          <AuthHeader headerText="Welcome To Hi Surat" />
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <div className={styles?.textfield}>
              <CustomTextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="custom-class"
                variant="outlined"
                placeholder="Enter your email"
              />
              <CustomTextField
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="custom-class"
                variant="outlined"
                placeholder="Enter your password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
              <CustomButton
                className={styles?.signInBtnWrapper}
                onClick={handleSignInClick}
                disabled={loading}
              >
                <CustomTypography
                  variant="body2"
                  className={styles?.btnTextWrapper}
                >
                  {loading ? "Signing In..." : "Sign In"}
                </CustomTypography>
              </CustomButton>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
