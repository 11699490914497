import React, { useState } from "react";
import { Grid, Box, Typography, Modal } from "@mui/material";
import DealCard from "./DealCard";
import { Deal } from "./types";
import DealDetailModal from "../Deal/DealDetailModal";

interface DealListProps {
  deals: Deal[];
}

const DealList: React.FC<DealListProps> = ({ deals }) => {
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (deal: Deal) => {
    setSelectedDeal(deal);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedDeal(null);
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Deals
      </Typography>
      {selectedDeal ? (
        <Grid container spacing={2}>
          {deals.map((deal) => (
            <Grid item xs={12} sm={6} md={4} key={deal._id}>
              <DealCard deal={deal} onViewDetails={handleOpenModal} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <DealDetailModal
          dealData={selectedDeal}
          onClose={() => setSelectedDeal(null)}
        />
      )}
    </Box>
  );
};

export default DealList;
