import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import styles from "./Business.module.css";
function BusinessImages({
  values,
  handleImageChange,
  brandLogoUrl,
  setFieldValue,
  setBrandLogoUrl,
  bgImageUrl,
  setBgImageUrl,
  gallaryImages,
  handleDragStart,
  handleDragOver,
  handleBannerImageChange,
  handleDrop,
  handleRemoveBannerImage,
  handleChange,
  handleAddBannerImage,
  handleSubmit,
  handleBack,
}: any) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Gallery & Media
        </Typography>

        {/* Brand Logo & Background Image */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" gutterBottom>
              Brand Logo
            </Typography>
            <Box my={1}>
              <CustomButton
                variant="outlined"
                disabled={values.brandLogo.startsWith("https")}
                onClick={() => document.getElementById("brandLogo")?.click()}
                sx={{
                  borderColor: values.brandLogo.startsWith("https")
                    ? "grey.400"
                    : "primary.main",
                  color: values.brandLogo.startsWith("https")
                    ? "grey.500"
                    : "primary.main",
                  "&:hover": {
                    borderColor: values.brandLogo.startsWith("https")
                      ? "grey.400"
                      : "primary.dark",
                    backgroundColor: values.brandLogo.startsWith("https")
                      ? "transparent"
                      : "rgba(0, 197, 185, 0.1)",
                  },
                }}
              >
                Upload Brand Logo
              </CustomButton>
            </Box>

            {!values.brandLogo.startsWith("https") && (
              <input
                id="brandLogo"
                type="file"
                accept="image/*"
                className={styles.galleryindividual}
                onChange={(e) =>
                  handleImageChange(
                    "brandLogo",
                    e.target.files?.[0],
                    setFieldValue
                  )
                }
              />
            )}
            <TextField
              label="Or Enter Image URL"
              value={brandLogoUrl ?? (values.brandLogo || "")}
              onBlurCapture={(e) => {
                if (brandLogoUrl?.startsWith("https")) {
                  setFieldValue("brandLogo", brandLogoUrl);
                } else {
                  setBrandLogoUrl(null);
                  setFieldValue("brandLogo", "");
                }
              }}
              onChange={(e) => setBrandLogoUrl(e.target.value)}
              fullWidth
              margin="normal"
            />
            {values.brandLogo && (
              <img
                src={values.brandLogo}
                alt="Brand Logo"
                className={styles.galleryImage}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body1" gutterBottom>
              Background Image
            </Typography>
            <Box my={1}>
              <CustomButton
                variant="outlined"
                disabled={values.bgImage.startsWith("https")}
                sx={{
                  borderColor: values.bgImage.startsWith("https")
                    ? "grey.400"
                    : "primary.main",
                  color: values.bgImage.startsWith("https")
                    ? "grey.500"
                    : "primary.main",
                  "&:hover": {
                    borderColor: values.bgImage.startsWith("https")
                      ? "grey.400"
                      : "primary.dark",
                    backgroundColor: values.bgImage.startsWith("https")
                      ? "transparent"
                      : "rgba(0, 197, 185, 0.1)",
                  },
                }}
                onClick={() => document.getElementById("bgImage")?.click()}
              >
                Upload Background Image
              </CustomButton>
            </Box>
            {!values.bgImage.startsWith("https") && (
              <input
                id="bgImage"
                type="file"
                accept="image/*"
                className={styles.galleryindividual}
                onChange={(e) =>
                  handleImageChange(
                    "bgImage",
                    e.target.files?.[0],
                    setFieldValue
                  )
                }
              />
            )}
            <TextField
              label="Or Enter Image URL"
              value={bgImageUrl ?? (values.bgImage || "")}
              onBlurCapture={(e) => {
                if (bgImageUrl?.startsWith("https")) {
                  setFieldValue("bgImage", bgImageUrl);
                } else {
                  setBgImageUrl(null);
                  setFieldValue("bgImage", "");
                }
              }}
              onChange={(e) => setBgImageUrl(e.target.value)}
              fullWidth
              margin="normal"
            />
            {values.bgImage && (
              <img
                src={values.bgImage}
                alt="Background"
                className={styles.galleryImage}
              />
            )}
          </Grid>
        </Grid>

        {/* Gallery Images Section */}
        <Divider style={{ margin: "16px 0" }} />
        <Typography variant="h6" gutterBottom>
          Gallery Images
        </Typography>
        <Grid container spacing={2}>
          {gallaryImages?.map((banner: any, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <input
                type="file"
                multiple
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    e.target.files ? Array.from(e.target.files) : []
                  )
                }
                style={{ display: "none" }}
                id={`banner-upload-${index}`}
              />
              <label htmlFor={`banner-upload-${index}`}>
                <Box className={styles.gallerybanner}>
                  {banner ? (
                    <img
                      src={banner}
                      alt={`banner-${index}`}
                      className={styles.gallerybannerindividual}
                    />
                  ) : (
                    <Typography color="primary">Upload Images</Typography>
                  )}
                </Box>
              </label>
              <TextField
                label="Or Enter Image URL"
                name={`gallery.image[${index}]`}
                value={values.gallery.image || ""}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Box mt={1}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveBannerImage(index)}
                  style={{ width: "100%" }}
                >
                  Remove Image
                </CustomButton>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={4}>
          <CustomButton variant="outlined" onClick={handleAddBannerImage}>
            Add Gallery Image
          </CustomButton>
        </Box>

        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton type="submit" variant="contained" color="primary">
              Submit
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BusinessImages;
