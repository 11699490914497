import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import CustomButton from "src/components/CustomButton";
import TimingSelect from "src/components/Common/TimingSelect";
import DynamicForm from "./DynamicForm";
import addIcon from "src/Images/Plus.svg";
import styles from "./Business.module.css";
function BusinessTIming({
  errors,
  addNewTimingField,
  timings,
  handleTimingChange,
  removeTimingField,
  usedDays,
  overviewJson,
  business,
  categoryId,
  setFieldValue,
  handleBack,
  handleNext,
}: any) {
  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          fontSize={26}
          fontWeight={600}
          marginX={"40px"}
          className={styles.timing}
        >
          Business Timings
        </Typography>
        <Box className={styles.timingBox}>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignSelf={"self-end"}
          >
            <CustomButton
              type="button"
              variant="outlined"
              style={{
                display: "flex",
                width: 200,
              }}
              onClick={addNewTimingField}
            >
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <img src={addIcon} alt="add" height={20} width={20}></img>
                <span className={styles.addTiming}>Add Timing</span>
              </Box>
            </CustomButton>
          </Box>
          {timings?.map((timing: any, index: number) => (
            <Box key={index} mt={2}>
              <TimingSelect
                key={index}
                timings={timings}
                onTimingChange={handleTimingChange}
                onRemove={removeTimingField}
                availableDays={usedDays}
                index={index}
                otherShown={false}
              />
              {errors.timings && (
                <Typography variant="caption" color="error" mx={1.5}>
                  {errors.timings}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        {/* Overview */}
        {overviewJson && (
          <Box mt={4}>
            <DynamicForm
              jsonData={business ? business?.overviews[0] : null}
              categoryId={categoryId}
              setFieldValue={setFieldValue}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton variant="contained" onClick={handleNext}>
              Next
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BusinessTIming;
