import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Business } from "./types";
import CustomButton from "src/components/CustomButton";

interface BusinessCardProps {
  business: Business;
  onViewDetails: (business: Business) => void;
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  business,
  onViewDetails,
}) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        margin: 2,
        boxShadow: 3,
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: 6,
        },
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <CardMedia
            component="img"
            sx={{ width: 60, height: 60, borderRadius: "50%", marginRight: 2 }}
            image={business?.brandLogo}
            alt={`${business?.businessName} Logo`}
          />
          <Box>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              {business.businessName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Contact: {business.mobileNumber}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Status: {business.status}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Total Reviews: {business.totalReviewsCount}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Average Rating: {business.averageRating}
            </Typography>
            <Box mt={2}>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => onViewDetails(business)}
                sx={{ fontWeight: "bold", textTransform: "none" }}
              >
                View Details
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BusinessCard;
