import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import OverviewComponent from "./OverView";
import { BusinessProfileProps } from "./Business";
import styles from "./BusinessProfile.module.css";
const BusinessProfileView: React.FC<BusinessProfileProps> = ({
  businessProfile,
}) => {
  const {
    businessName,
    brandName,
    brandLogo,
    bgImage,
    overviews,
    subCategory,
    mobileNumber,
    timings,
    address,
    galleries,
    user,
  } = businessProfile;

  return (
    <Box p={3} maxWidth={1200} marginX={"auto"}>
      <Card className={styles.card}>
        <CardMedia
          component="img"
          height="200"
          image={bgImage}
          alt={businessName}
          className={styles.cardMedia}
        />
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {businessName}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {brandName}
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <Box height={70} width={150}>
              <CardMedia
                component="img"
                className={styles.cardMediaMain}
                image={brandLogo}
                alt={brandName}
              />
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                {subCategory}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Mobile: {mobileNumber}
              </Typography>
              {user?.email && (
                <Typography variant="body2" color="textSecondary">
                  Email: {user.email}
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      {galleries?.length > 0 && (
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Gallery
          </Typography>
          <Grid container spacing={2} className={styles.gallery}>
            {galleries[0].image.map((img, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CardMedia
                  component="img"
                  height="300"
                  image={img}
                  alt={`Gallery image ${index + 1}`}
                  className={styles.cardMedia}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Timings
        </Typography>
        <Grid container spacing={2}>
          {timings[0]?.timings?.map((timing: any, index: any) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card variant="outlined" className={styles.timingCard}>
                <CardContent className={styles.timingCardContent}>
                  <Typography variant="h6">{timing.day}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {timing.startTime} - {timing.endTime}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {address?.addressses?.length > 0 && (
        <Box mb={3} className={styles.addressContainer}>
          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
          {address?.addressses?.map((addr: any, index: any) => (
            <Box key={index} mb={1}>
              <Typography variant="body2" mb={0.5}>
                {addr?.address}
              </Typography>
              <Link
                href={addr.googleMapLink}
                target="_blank"
                rel="noopener"
                className={styles.link}
              >
                View on Google Maps
              </Link>
            </Box>
          ))}
        </Box>
      )}

      <Typography variant="h4" gutterBottom className={styles.overviewSection}>
        Overview
      </Typography>
      <OverviewComponent data={overviews[0]} />
    </Box>
  );
};

export default BusinessProfileView;
