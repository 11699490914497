import { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BUSINESS_PROFILE,
  UPDATE_BUSINESS_PROFILE,
} from "src/graphql/mutations";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { uploadImage, uploadImages } from "src/components/Common/Utils";
import { GET_ALL_SUBCATEGORIES } from "src/graphql/query";
import { FormValues } from "./Business";
import backButton from "../../Images/backButton.svg";
import BasicInfo from "./BasicInfo";
import AddressInfo from "./AddressInfo";
import BusinessTIming from "./BusinessTIming";
import BusinessImages from "./BusinessImages";
interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  __typename?: string;
}
const BusinessModal = ({ onClose, business, refetch }: any) => {
  const [overviewJson, setOverviewJson] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>("");
  const [timings, setTimings] = useState<Timing[]>(
    business?.timings?.[0]?.timings || []
  );
  const [activeStep, setActiveStep] = useState(0);
  const [createBusinessProfile] = useMutation(CREATE_BUSINESS_PROFILE);
  const [updateBusinessProfile] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [gallaryImages, setGallaryImages] = useState<any[]>(
    business?.galleries[0]?.image || []
  );
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [bgImageUrl, setBgImageUrl] = useState<string | null>(null);
  const [brandLogoUrl, setBrandLogoUrl] = useState<string | null>(null);
  // Handle drag start
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...gallaryImages];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);

    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setGallaryImages(updatedImages);
    setDraggingIndex(null);
  };
  const initialValues: FormValues = {
    UserName: business?.UserName || "",
    businessName: business?.businessName || "",
    primarySubCategory: business?.primarySubCategory?._id || "",
    additionalSubCategories: business
      ? business?.additionalSubCategories?.map(
          (subCategory: any) => subCategory._id
        ) || []
      : [],
    brandName: business?.brandName || "",
    brandLogo: business?.brandLogo || "",
    website: business?.website || "",
    businessEmail: business?.businessEmail || "",
    documents: [],
    managedBy: business?.managedBy || "",
    mobileNumber: business?.mobileNumber || "",
    bgImage: business?.bgImage || "",
    socialMedia: business?.socialMedia || [],
    owners: business?.owners
      ? {
          firstName: business?.owners[0]?.firstName,
          lastName: business?.owners[0]?.lastName,
          mobileNumber: business?.owners[0]?.mobileNumber,
          email: business?.owners[0]?.email,
        }
      : {
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
        },
    addresses: business?.address?.addressses ?? [
      {
        address: "",
        buildingOrShopNumber: "",
        landmark: "",
        neighborhood: "",
        googleMapLink: "",
        pinCode: "",
      },
    ],
    gallery: business?.galleries[0]?.image ?? { image: "", video: "" },
    timing: business?.timings?.[0]?.timings || [
      {
        day: "",
        startTime: "",
        endTime: "",
      },
    ],
    overview: null,
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleImageChange = async (
    field: string,
    file: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (file) {
      try {
        const data = await uploadImage(file);
        const imageUrl = data?.uploadImage?.url;
        if (imageUrl) {
          setFieldValue(field, imageUrl);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      setFieldValue(field, null);
    }
  };
  const handleImageUpload = async (
    gallaryImages: Array<{ imageUrl: string }>
  ) => {
    // Convert blob URLs to File objects
    const filesToUpload = gallaryImages.map((banner) => {
      const fileUrl = banner?.imageUrl;

      if (fileUrl?.includes("blob:")) {
        return fetch(fileUrl)
          .then((res) => res.blob())
          .then(
            (blob) => new File([blob], "uploaded_image", { type: blob.type })
          );
      }
      return null;
    });

    // Wait for all promises to resolve
    const resolvedFiles = await Promise.all(filesToUpload);

    // Filter out null values and only keep valid files
    const filesToUploadFiltered = resolvedFiles.filter(
      (file): file is File => file !== null
    );

    // Upload files and get URLs
    const uploadedImages = filesToUploadFiltered.length
      ? await uploadImages(filesToUploadFiltered)
      : gallaryImages;
    // Extract URLs from uploaded images
    const uploadedUrls = uploadedImages?.uploadMultipleImages?.map(
      (image: any) => image
    );
    // Update gallery images with new URLs
    const updatedBannerImages = gallaryImages.map(
      (banner, index) => uploadedImages[index] || banner?.imageUrl
    );

    return updatedBannerImages;
  };
  const handleSubmit = async (values: FormValues) => {
    try {
      const images = await handleImageUpload(gallaryImages);

      const overview =
        typeof values?.overview === "string" && values?.overview
          ? values?.overview
          : values?.overview;
      if (values?.addresses) {
        values.addresses = values.addresses.map(
          ({ __typename, ...addressWithoutTypename }) => addressWithoutTypename
        );
      }

      if (values?.timing) {
        values.timing = timings.map(
          ({ __typename, ...timingWithoutTypename }) => {
            return timingWithoutTypename;
          }
        );
      }
      if (values?.socialMedia) {
        values.socialMedia = values.socialMedia.map(
          ({ __typename, ...socialMediaWithoutTypename }) => {
            return socialMediaWithoutTypename;
          }
        );
      }
      const variables = {
        input: {
          ...values,
          timing: values?.timing,
          overview,
          documents: [],
          gallery: { image: images },
        },
      };

      const { primarySubCategory, ...rest } = variables?.input;

      const res = business
        ? await updateBusinessProfile({
            variables: {
              _id: business?._id,
              input: rest,
            },
          })
        : await createBusinessProfile({ variables });

      if (
        res?.data?.createBusinessProfile?.statusCode === 200 ||
        res?.data?.updateBusinessProfileDetails?.statusCode === 200
      ) {
        toast.success(
          res?.data?.createBusinessProfile?.message ||
            res?.data?.updateBusinessProfileDetails?.message ||
            "Business Profile created successfully"
        );
        refetch();
        onClose();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const { data } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });
  const subCategoryData = data?.getAllSubCategories?.data;

  const handleTimingChange = (
    index: number,
    field: keyof any,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);
  };

  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([...timings, { day: "", startTime: "", endTime: "" }]);
    }
  };
  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };
  const usedDays = timings.map((timing) => timing.day);

  const handleAddBannerImage = () => {
    setGallaryImages([...gallaryImages, {}]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...gallaryImages];
    updatedBannerImages.splice(index, 1);
    setGallaryImages(updatedBannerImages);
  };
  //Banner Image function
  const handleBannerImageChange = (index: number, files: FileList | null) => {
    if (!files) return;

    // Create an array from the FileList
    const newFiles = Array.from(files);

    // Map over the new files to create URLs
    const newImageUrls = newFiles.map((file) => ({
      imageUrl: URL.createObjectURL(file),
    }));

    // Update the existing gallaryImages with the new images
    const updatedBannerImages = [...gallaryImages];

    // Insert the new images into the gallery starting at the given index
    updatedBannerImages.splice(index, 0, ...newImageUrls);

    // Update the state with the modified images array
    setGallaryImages(updatedBannerImages);
  };

  const steps = [
    "Basic Information",
    "Address Details",
    "Business Timings",
    "Gallery & Media",
  ];
  return (
    <Box>
      <Box p={2}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={onClose}
          />
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#00C5B9" }}
            marginTop={2}
          >
            {business ? "Update Business" : "Create Business"}
          </Typography>
        </Box>
        <Divider />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginY: "12px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setActiveStep(index);
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, setFieldValue }: any) => (
            <Form>
              {/* Step 1: Business Basic Information */}
              {/* Business Name */}

              {activeStep === 0 && (
                <BasicInfo
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  touched={touched}
                  onClose={onClose}
                  handleNext={handleNext}
                />
              )}
              {/* after this will going on step 2 */}
              {/* Addresses */}
              {activeStep === 1 && (
                <AddressInfo
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBack={handleBack}
                  setOverviewJson={setOverviewJson}
                  subCategoryData={subCategoryData}
                  setCategoryId={setCategoryId}
                  handleNext={handleNext}
                />
              )}

              {/* Timings Management */}
              {activeStep === 2 && (
                <BusinessTIming
                  errors={errors}
                  addNewTimingField={addNewTimingField}
                  timings={timings}
                  handleTimingChange={handleTimingChange}
                  removeTimingField={removeTimingField}
                  usedDays={usedDays}
                  overviewJson={overviewJson}
                  business={business}
                  categoryId={categoryId}
                  setFieldValue={setFieldValue}
                  handleBack={handleBack}
                  handleNext={handleNext}
                />
              )}

              {/* gallery and image section */}
              {activeStep === 3 && (
                <BusinessImages
                  values={values}
                  handleImageChange={handleImageChange}
                  brandLogoUrl={brandLogoUrl}
                  setFieldValue={setFieldValue}
                  setBrandLogoUrl={setBrandLogoUrl}
                  bgImageUrl={bgImageUrl}
                  setBgImageUrl={setBgImageUrl}
                  gallaryImages={gallaryImages}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleBannerImageChange={handleBannerImageChange}
                  handleDrop={handleDrop}
                  handleRemoveBannerImage={handleRemoveBannerImage}
                  handleChange={handleChange}
                  handleAddBannerImage={handleAddBannerImage}
                  handleSubmit={handleSubmit}
                  handleBack={handleBack}
                />
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default BusinessModal;
