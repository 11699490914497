import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_PINCODE_MUTATION, UPDATE_PINCODE } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import * as yup from "yup";
import backButton from "../../Images/backButton.svg";
import { uploadImage } from "src/components/Common/Utils";

const validationSchema = yup.object().shape({
  areaName: yup.string().required("Area Name is required"),
  pinCode: yup
    .array()
    .of(
      yup.object().shape({
        city: yup.string().required("City is required"),
        pincode: yup
          .string()
          .matches(/^[0-9]{6}$/, "Each Pin Code must be a 6-digit number")
          .required("Pin Code is required"),
      })
    )
    .min(1, "At least one Pin Code is required"),
});

interface Pincode {
  city: string;
  postoffice: string;
  pincode: string;
}

interface PincodePageProps {
  refetchPincodes: () => void;
  handleClose: () => void;
  initialPinCodeObj?: { areaName: string; pinCode: Pincode[] };
  pinCodeObj?: {
    _id: any;
    areaName: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    imageUrl?: string;
    pinCode: Pincode[];
    __typename?: string;
  };
  setPinCodeObj?: (value: any) => void;
  isEditPincode?: boolean;
}

const PincodeModal: React.FC<PincodePageProps> = ({
  refetchPincodes,
  handleClose,
  initialPinCodeObj = {
    areaName: "",
    pinCode: [],
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    imageUrl: "",
  },
  pinCodeObj = {
    areaName: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    imageUrl: "",
    pinCode: [],
    _id: "",
    __typename: "",
  },
  setPinCodeObj = () => {},
  isEditPincode = false,
}) => {
  const [errors, setErrors] = useState<any>({});
  const [newCity, setNewCity] = useState<string>("");
  const [newPinCode, setNewPinCode] = useState<string>("");
  const [newPostOffice, setNewPostOffice] = useState<string>("");
  const [addPinCodeMutation] = useMutation(ADD_PINCODE_MUTATION);
  const [updatePinCodeMutation] = useMutation(UPDATE_PINCODE);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isEditPincode && pinCodeObj) {
      setPinCodeObj(pinCodeObj);
      setPreviewImage(pinCodeObj?.imageUrl ?? null);
    } else {
      setPinCodeObj(initialPinCodeObj);
    }
  }, []);

  const handleAddPinCode = () => {
    if (
      newCity.trim() &&
      newPinCode.trim().length === 6 &&
      /^\d+$/.test(newPinCode)
    ) {
      setPinCodeObj((prev: any) => ({
        ...prev,
        pinCode: [
          ...prev.pinCode,
          { city: newCity, pincode: newPinCode, postoffice: newPostOffice },
        ],
      }));
      setNewCity("");
      setNewPinCode("");
      setNewPostOffice("");
      setErrors({ ...errors, pinCode: "" });
    } else {
      setErrors({
        ...errors,
        pinCode:
          "Invalid input. City must be filled, and pin code must be 6 digits.",
      });
    }
  };

  const handleRemovePinCode = (index: number) => {
    setPinCodeObj((prev: any) => ({
      ...prev,
      pinCode: prev.pinCode.filter((_: any, i: number) => i !== index),
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setImageFile(file);
    setPreviewImage(file ? URL.createObjectURL(file) : null);
  };

  const handleImageRemove = () => {
    setPinCodeObj((prev: any) => ({ ...prev, imageUrl: "" }));
    setImageFile(null);
    setPreviewImage(null);
  };
  const handleSubmit = async () => {
    try {
      let imageUrl = pinCodeObj?.imageUrl;
      await validationSchema.validate(pinCodeObj, { abortEarly: false });
      setErrors({});
      // Upload image if there's an image file selected
      if (imageFile) {
        const response = await uploadImage(imageFile);
        if (response?.uploadImage?.url) {
          imageUrl = await response?.uploadImage?.url;
        } else {
          throw new Error("Image upload failed.");
        }
      }
      setPinCodeObj((prev: any) => ({ ...prev, imageUrl: imageUrl }));
      const { _id, __typename, ...restData } = pinCodeObj;
      const { data } = isEditPincode
        ? await updatePinCodeMutation({
            variables: {
              _id: _id,
              input: {
                ...restData,
                imageUrl: imageUrl,
              },
            },
          })
        : await addPinCodeMutation({
            variables: {
              input: {
                ...restData,
                imageUrl: imageUrl,
              },
            },
          });
      refetchPincodes();
      if (
        data?.addPinCode?.statusCode === 200 ||
        data?.updatePinCode?.statusCode === 200
      ) {
        toast.success(
          data?.addPinCode?.message ||
            data?.updatePinCode?.message ||
            "Pincode saved successfully!"
        );
        setPinCodeObj(initialPinCodeObj);

        handleClose();
      }
    } catch (err: any) {
      if (err?.inner) {
        const validationErrors: any = {};
        err.inner.forEach((validationError: any) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(err?.message || "An unexpected error occurred.");
      }
    }
  };

  return (
    <Box padding={4}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={handleClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEditPincode ? "Update Pincode" : "Create Pincode"}
        </Typography>
      </Box>

      <Box>
        <TextField
          name="areaName"
          margin="dense"
          label="Area Name"
          fullWidth
          required
          value={pinCodeObj?.areaName || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, areaName: e.target.value })
          }
          error={!!errors.areaName}
          helperText={errors.areaName}
        />
        <TextField
          name="metaTitle"
          margin="dense"
          label="Meta Title"
          fullWidth
          value={pinCodeObj?.metaTitle || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, metaTitle: e.target.value })
          }
        />
        <TextField
          name="metaDescription"
          margin="dense"
          label="Meta Description"
          fullWidth
          value={pinCodeObj?.metaDescription || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, metaDescription: e.target.value })
          }
          multiline
          rows={4}
        />
        <TextField
          name="metaKeywords"
          margin="dense"
          label="Meta Keywords"
          fullWidth
          value={pinCodeObj?.metaKeywords || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, metaKeywords: e.target.value })
          }
          multiline
          rows={4}
        />
        <Box marginTop={2}>
          {previewImage ? (
            <Box
              sx={{
                height: "200px",
                borderRadius: 2,

                overflow: "hidden",
                position: "relative",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  border: "2px dashed #00C5B9",
                  width: "200px",
                }}
              >
                <img
                  src={previewImage}
                  alt="event"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box ml={2}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleImageRemove}
                >
                  Remove
                </CustomButton>
              </Box>
            </Box>
          ) : (
            <label>
              <input
                ref={fileInputRef}
                id="eventImage"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <CustomButton
                variant="outlined"
                type="button"
                size="small"
                onClick={() => fileInputRef?.current?.click()}
              >
                Upload Image
              </CustomButton>
              {!!errors.imageUrl && (
                <Typography variant="subtitle2" mt={1} mx={2} color={"#D32f2f"}>
                  {errors.imageUrl}
                </Typography>
              )}
            </label>
          )}
        </Box>
        <Box display="flex" gap={2} mt={2}>
          <TextField
            name="city"
            margin="dense"
            label="City"
            fullWidth
            value={newCity}
            onChange={(e) => setNewCity(e.target.value)}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
          <TextField
            name="postoffice"
            margin="dense"
            label="Post Office"
            fullWidth
            value={newPostOffice}
            onChange={(e) => setNewPostOffice(e.target.value)}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
          <TextField
            name="pincode"
            margin="dense"
            label="Pin Code"
            fullWidth
            value={newPinCode}
            onChange={(e) => setNewPinCode(e.target.value)}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
        </Box>
        <CustomButton
          variant="outlined"
          style={{ marginTop: "16px" }}
          onClick={handleAddPinCode}
        >
          Add Pin Code
        </CustomButton>
        <List sx={{ maxHeight: 300, mt: 2, overflow: "scroll" }}>
          {pinCodeObj?.pinCode?.map((pinCode: Pincode, index: number) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemovePinCode(index)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`City: ${pinCode?.city}, Post Office: ${pinCode?.postoffice}, Pincode: ${pinCode?.pincode}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <CustomButton onClick={handleSubmit} variant="contained">
          {isEditPincode ? "Update" : "Create"}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PincodeModal;
