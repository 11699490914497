import React, { useState } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import CustomTable from "src/components/CustomTable";
import CustomButton from "src/components/CustomButton";
import FaqModal from "./FaqModal";
import DeleteModel from "src/components/Common/DeleteModel";
import { ADD_FAQ, DELETE_FAQ, UPDATE_FAQ } from "src/graphql/mutations";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { Faq, FaqProps } from "./SubCategory";
import styles from "./SubCategory.module.css";

const FaqTable: React.FC<FaqProps> = ({ initialFaqs, reload }) => {
  const [faqs, setFaqs] = useState<Faq[]>(initialFaqs);
  const [newFaq, setNewFaq] = useState<Omit<Faq, "_id">>({
    question: "",
    answer: "",
  });
  const { id } = useParams<{ id: string }>();
  const [editFaqId, setEditFaqId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteFaqId, setDeleteFaqId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [AddFaq] = useMutation(ADD_FAQ);
  const [UpdateFaq] = useMutation(UPDATE_FAQ);
  const [DeleteFaq] = useMutation(DELETE_FAQ);

  const COLUMNS = [
    { headerName: "Question", field: "question", flex: 2 },
    { headerName: "Answer", field: "answer", flex: 3 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditFaq(row._id);
              setEditMode(true);
              setOpenModal(true);
            }}
            aria-label="edit"
            className={styles.editIcon}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            className={styles.deleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteFaqId(row._id);
              setOpenDeleteModel(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleAddFaq = async () => {
    const newEntry: Faq = {
      ...newFaq,
      _id: `${Date.now()}`,
    };
    setFaqs([...faqs, newEntry]);
    const variables = {
      input: {
        question: newFaq?.question,
        answer: newFaq?.answer,
        subCategoryId: id,
      },
    };
    const res = await AddFaq({ variables });
    if (res?.errors) {
      toast.error("Internal Error");
      return;
    }
    setNewFaq({ question: "", answer: "" });
    toast.success("FAQ added successfully!");
    setOpenModal(false);
    reload();
  };

  const handleDeleteFaq = async (id: string) => {
    setFaqs(faqs.filter((faq) => faq._id !== id));
    const res = await DeleteFaq({
      variables: {
        _id: id,
      },
    });
    if (res?.errors) {
      toast.error("Internal Error");
      return;
    }
    toast.success("FAQ deleted successfully!");
    reload();
  };

  const handleEditFaq = (id: string) => {
    const faqToEdit = faqs.find((faq) => faq._id === id);
    if (faqToEdit) {
      setEditFaqId(faqToEdit._id);
      setNewFaq({
        question: faqToEdit.question,
        answer: faqToEdit.answer,
      });
    }
  };

  const handleSaveEdit = async () => {
    setFaqs(
      faqs.map((faq) => (faq._id === editFaqId ? { ...faq, ...newFaq } : faq))
    );
    const variables = {
      _id: editFaqId,
      input: {
        question: newFaq?.question,
        answer: newFaq?.answer,
        subCategoryId: id,
      },
    };
    const res = await UpdateFaq({ variables });
    if (res?.errors) {
      toast.error("Internal Error");
      return;
    }
    setEditFaqId(null);
    toast.success("FAQ updated successfully!");
    setOpenModal(false);
    reload();
  };

  const handleCloseModal = () => {
    setNewFaq({ question: "", answer: "" });
    setEditFaqId(null);
    setOpenModal(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteFaqId) {
      await handleDeleteFaq(deleteFaqId);
      setDeleteFaqId(null);
      setOpenDeleteModel(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h6" className={styles.header}>
          Frequently Asked Questions
        </Typography>
        <Box width={100}>
          <CustomButton
            onClick={() => {
              setEditMode(false);
              setOpenModal(true);
            }}
            variant="contained"
          >
            Create
          </CustomButton>
        </Box>
      </Box>

      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={faqs}
          paginationModel={pagination}
          totalCount={faqs.length}
          onPageChange={handlePageChange}
          onPageSizeChange={() => {}}
          onRowClick={() => {}}
        />
      </Box>

      <FaqModal
        open={openModal}
        faqData={newFaq}
        setFaqData={setNewFaq}
        handleClose={handleCloseModal}
        handleSaveEdit={editMode ? handleSaveEdit : handleAddFaq}
        isEditMode={editMode}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this FAQ?"
        />
      )}
    </Box>
  );
};

export default FaqTable;
